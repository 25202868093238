.CarrouselBanner-Container {
  background: linear-gradient(
    100.25deg,
    #0d0c54 0%,
    #1c21a8 43.5%,
    #1f7ae0 96.5%
  );
  width: 100%;
  height: 627px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}
.carousel-img-right-container {
  display: flex;
  justify-content: flex-end;
}
.carousel-img-right-container2 {
  display: flex;
  justify-content: flex-end;
}
.carrouselBanner-overlap {
  position: absolute;
  max-width: 1302px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 64px;
  height: 100%;
  width: 100%;
}
.CarrouselBanner-textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 57%;
}
.CarrouselBanner-textContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 57%;
}

.CarrouselBanner-textContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 57%;
}
.CarrouselBanner-textContainer3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 57%;
}

.CarrouselBanner-Title {
  width: max-content;
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  text-align: left;
  color: #ffffff;
  margin-bottom: 4px;
}
.CarrouselBanner-SubTitle {
  font-family: Poppins;
  font-size: clamp(0.75rem, -0.7618rem + 2.3599vw, 1.25rem);
  font-weight: 400;
  line-height: clamp(1.125rem, -1.1427rem + 3.5398vw, 1.875rem);
  text-align: left;
  color: #ffffff;
  margin-bottom: 36px;
}
.CarrouselBanner-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Metropolis;
  font-size: 1rem;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 134px;
  height: 48px;
  border-radius: 6px;
  background-color: #ff7a00;
  color: #ffff;
  border-color: #ff7a00;
  overflow: hidden;
  box-sizing: border-box;
}

.carousel-text-right {
  justify-content: flex-start !important;
  margin-left: auto;
}
@media screen and (max-width: 1024px) {
  .carrouselBanner-overlap {
    padding-right: 32px;
  }
  .carousel-text-right {
    margin-left: 185px;
  }
  .CarrouselBanner-textContainer {
    width: auto;
  }
}

@media screen and (max-width: 720px) {
  .carousel-img-right-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 202px;
    right: -145px;
  }
  .carousel-img-right-container2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -191px;
    top: -5px;
  }
  .CarrouselBanner-Container {
    background: linear-gradient(
      100.25deg,
      #0d0c54 0%,
      #1c21a8 43.5%,
      #1f7ae0 96.5%
    );
    width: 100%;
    height: 574px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .carrouselBanner-overlap {
    position: absolute;
    max-width: 1302px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .CarrouselBanner-Title {
    font-family: Metropolis;
    font-size: 18px !important;
    font-weight: 800;
    line-height: 18px !important;
    text-align: left;
    color: #ffffff;
    margin-bottom: 4px;
    width: fit-content !important;
  }
  .CarrouselBanner-textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: auto;
    margin-top: 172px;
    margin-left: 16px;
    margin-right: 16px;
    width: 296px;
  }
  .CarrouselBanner-textContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: auto;
    margin-top: 172px;
    margin-left: 16px;
    margin-right: 16px;
    width: 296px !important;
  }
  .CarrouselBanner-textContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: auto;
    margin-top: 292px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
    width: 296px !important;
  }
  .CarrouselBanner-textContainer3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: auto;
    margin-top: 32px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 290px;
    width: 296px !important;
  }
  .CarrouselBanner-SubTitle {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 18px;
  }

  .CarrouselBanner-Button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Metropolis;
    font-size: 12px !important;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 92px !important;
    height: 32px !important;
    border-radius: 6px;
    background-color: #ff7a00;
    color: #ffff;
    border-color: #ff7a00;
    overflow: hidden;
    box-sizing: border-box;
  }
  .carousel-text-right {
    margin-left: 16px;
    margin-top: 42px;
    margin-right: 32px;
    width: 280px;
  }
}
