.why-us-container {
  margin-top: 32px;
  /* margin-left: 32px;
     margin-right: 32px; */
  /* height: 350px; */
  display: flex;
  column-gap: 26px;
  justify-content: center;
}
@media screen and (max-width: 1050px) {
  .why-us-container {
    column-gap: 28px;
  }
}
.home-whyus-parent-container {
  max-width: 1366px;
  padding: 0 32px;
  margin: 0 auto;
  margin-top: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem);
  margin-bottom: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem);
}
.choose-Us-heading {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 800;
  line-height: 48px;
  text-align: center;
  color: #033b81;
}
@media screen and (max-width: 1024px) {
  .choose-Us-heading {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 800;
    line-height: 42px;
    text-align: center;
  }
}
@media screen and (max-width: 720px) {
  .choose-Us-heading {
    font-family: Metropolis;
    font-size: 18px !important;
    font-weight: 800;
    line-height: 18px !important;
    text-align: center;
    color: #033b81;
  }
  .why-us-container {
    flex-direction: column;
    margin-top: 16px;
    align-items: center;
  }

  
  .home-whyus-parent-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin-top: 48px;
    margin-bottom: 48px;
  }

}
