.carousel-container {
  border-radius: 16px; /*  mehul change */
  margin: 32px 32px 32px 32px;
}
@media screen and (max-width: 720px) {
  .carousel-container {
    height: 574px;
    border-radius: 8px;
    margin: 16px 16px 8px 16px;
  }
  .carousel .carousel-indicators button {
    width: 24px !important;
    height: 4px !important;
    border: 0px;
    border-radius: 11px;
  }
}
.carousel .carousel-indicators button {
  width: 43px;
  height: 8px;
  border: 0px;
  border-radius: 11px;
}
.carousel-indicators {
  bottom: -3rem;
}
.img-slider {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  overflow-x: hidden;
}
.img-slider .slider-container {
  position: absolute;
  width: 300%;
  top: 0;
  left: 0;
  overflow: hidden;
  animation: slider 26s infinite;
}
.img-slider .slider-container .slide {
  position: relative;
  width: 33.33333333%;
  height: 100%;
  float: left;
  overflow: hidden;
}
.img-slider .slider-container .slide img {
  display: block;
  width: 100%;
  height: 100%;
}
@keyframes slider {
  0%,
  25%,
  100% {
    left: 0;
  }

  30%,
  55% {
    left: -100%;
  }

  60%,
  85% {
    left: -200%;
  }
}
.carousel-indicators [data-bs-target] {
  margin: 0 2.5px !important;
}

.carousel-indicators .active {
  background-color: #033b81 !important;
}

/* @media screen and (min-width: 1250px) {
  .slick-track {
    width: 5648px !important;
  }
  .slick-slide {
    width: 386px !important;
  }
} */
