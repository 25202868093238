body {
  font-family: "Metropolis", sans-serif;
}

html {
  font-size: 16px;
}

button,
button:hover,
button:focus,
button:active {
  box-shadow: none; /* or box-shadow: transparent; */
}
html,
body {
  scroll-behavior: auto !important;
}
