.header-container {
  width: 100%;

  background-color: #000123;
  height: 64px;
  position: fixed;
  top: 0;
  z-index: 10000;
}
.header-content {
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
}
ul.header-links,
li.header-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}
.header-link {
  box-sizing: border-box;
  padding: 8px 16px !important;
  font-weight: 600;
  font-size: 1rem;
  color: #fff;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  font-family: Metropolis;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.active {
  background-color: #ffffff;
  color: #000123;
  line-height: normal;
  /* text-decoration: underline;
  text-underline-offset: 7px; */
}
.header-link:hover {
  /* text-decoration: underline;
  text-underline-offset: 7px; */
}
.header-logo {
  color: #fff;
  display: flex;
  align-items: center;
}
body {
  margin: 0;
}

.hamburger-icon {
  position: relative;
  display: none;
  place-items: center;
  cursor: pointer;
}
.deprontoLogo {
  width: 140.19px;
  height: 42px;
}
.header-shadow {
  height: 64px;
}
/* mobile view */

@media screen and (max-width: 720px) {
  .hamburger-icon {
    display: grid;
  }
  .header-links {
    display: none;
  }
  .header-container {
    height: 56px;
  }
  .header-content {
    padding: 0 16px;
  }
  .deprontoLogo {
    width: 80px;
    height: 24px;
  }
  .header-shadow {
    height: 56px;
  }
}
.header-menu-link {
  box-sizing: border-box;
  text-decoration: none;
  width: 136px;
  height: 32px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #535b62;
  padding: 7px 24px 7px 12px;
  cursor: pointer;
}
.header-menu-link-container {
  height: 128px;
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 1px 10px 0px #00000026;
  border-radius: 6px;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #ffffff;
}

.header-menu-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
