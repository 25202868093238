.careerJourney-header {
  font-family: Poppins;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: clamp(2.625rem, 1.4912rem + 1.7699vw, 3rem);
  letter-spacing: 0em;
  text-align: center;
  color: #ff7a00;
  margin-bottom: 16px;
}
.whyChooseUs {
  margin-top: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem);
}
.carrerdiv {
  margin: 32px 32px clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem) 32px;
}
.careeropportunitiesdiv {
  max-width: 1366px;
  margin: 0 auto;
  margin-top: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem);
}

.careerJourney-description {
  font-family: Metropolis;
  font-size: clamp(0.875rem, 0.4971rem + 0.59vw, 1rem);
  font-weight: 500;
  line-height: clamp(1.3125rem, 0.7456rem + 0.885vw, 1.5rem);
  letter-spacing: 0em;
  text-align: center;
  color: #2f363f;
}
.careerJourney-footer {
  font-family: Metropolis;
  font-size: clamp(1.125rem, -0.0088rem + 1.7699vw, 1.5rem);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  background-color: #47acdf;
  padding: 64px 0;
  width: 100%;
}

@media screen and (max-width: 720px) {
  .careeropportunitiesdiv {
    margin: 0 auto;
    margin-top: 48px !important;
  }
  .whyChooseUs {
    margin-top: 48px;
  }
  .carrerdiv {
    margin: 16px 16px 48px 16px !important;
  }
  .careerJourney-container {
    margin-top: 143px !important;
    gap: 36px !important;
  }
  .careerJourney-header {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .careerJourney-description {
    font-size: 12px;
    line-height: 18px;
  }
  .careerJourney-footer {
    height: 152px;
    padding: 58px 9.5px;
    font-size: 12px;
    line-height: 18px;
  }
}

.careerJourney-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 99px;
  margin-top: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem);
}
.careerJourney-text {
  max-width: 1302px;
}
@keyframes scrollAnimation {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animation {
  animation-name: scrollAnimation;
  animation-duration: 1s;
}
