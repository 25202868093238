/* .contactus-Banner-text {
  position: absolute;
  bottom: 35px;
  right: 47px;
  color: #033b81;
  font-family: Metropolis;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.contactus-Banner {
  position: relative;
}
.contactus-Banner-img {
  width: 100%;
  height: 215px;
} */
.contactus-page-form-container {
  max-width: 1302px;
  margin: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem) auto
    clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem) auto;
}
.contactUsBanner {
  overflow: hidden;
  height: 540px;
  background: linear-gradient(
    100.25deg,
    #0d0c54 0%,
    #1c21a8 43.5%,
    #1f7ae0 96.5%
  );
  border-radius: 16px;
}
.contactUsBanner-itemContainer {
  width: 100%;
  height: 100%;
  max-width: 1302px;
  margin: 0 auto;
  padding: 64px;
  position: relative;
}
.contactImg {
  position: absolute;
  bottom: 64px;
  right: 64px;
  z-index: 100;
}
.mapImg {
  position: absolute;
  bottom: 0;
  right: 32px;
  z-index: 50;
}
.contactUs-Text {
  height:134px ;
  width:642px ;
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 64px;
  left: 64px;
  z-index: 150;
  flex-direction: column;
  width: 50%;
}
.contactBannerTitle {
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  text-align: left;
  color: #ffffff;
  margin-bottom: 12px;
}
.contactBannerSubTitle {
  font-family: Poppins;
  font-size: clamp(0.75rem, -0.7618rem + 2.3599vw, 1.25rem);
  font-weight: 500;
  line-height: clamp(1.125rem, -1.1427rem + 3.5398vw, 1.875rem);
  text-align: left;
  color: #ffffff;
  margin-bottom: 16px;
}
.contactus-page-colntainer {
  margin: 32px 32px 0 32px;
}
@media screen and (min-width: 720px) and (max-width: 1250px) {
  .contactUs-Text {
    height:90px ;
    width:419px ;
  }
  .contactBannerTitle {
    margin-bottom: 8px;
  }
  .contact-form {
    column-gap: 32px;
  }
  .form-button {
    margin-top: 0px;
  }
}
/* mobile view */

@media screen and (max-width: 720px) {
  .contactus-page-colntainer {
    margin: 16px 16px 48px 16px;
  }
  .contactUsBanner{
    height: 574px;
  }
  .contactUs-Text {
    top: 42px;
    left: 16px;
    max-width: 280px;
    max-height: 94px;
    height: 100%;
    width: 100%;
    min-width: 0%;
    min-height: 0%;
  }
  .contactBannerTitle {
    font-family: Metropolis;
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    text-align: left;
    margin-bottom: 4px;
  }
  .contactBannerSubTitle {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }

  .mapImg {
    bottom: 0;
    left: 0;
  }
  .contacticon-img {
    height: 98px;
    width: 98px;
  }
  .contactImg {
    bottom: 16px;
    right: 16px;
  }
  .contactus-page-form-container {
    margin-top: 48px;
    margin-bottom: 0px;
  }
}
