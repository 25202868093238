.dropDownSearch input {
  box-sizing: border-box !important;
  width: 272px !important;
  height: 36px !important;
  border-radius: 4px !important;
  border: 1px solid #dde0e4 !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: left !important;
  color: #81878c !important;
  padding-left: 38px !important;
}
.dropDownSearch {
  display: flex;
  justify-content: center;
  padding-bottom: 6px;
  padding-top: 16px;
  background: #ffffff;
  position: sticky;
  top: 0;
}
.dropDownSearch input::placeholder {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #81878c;
}
.dropDownSearch input::placeholder::after {
  content: "SVG";
}
.dropdownContainer {
  position: absolute;
  right: 0;
  top: 79px;
  z-index: 1000;
  background: #ffffff;
}
.dropDownOptions {
  cursor: pointer;
  padding: 8px 16px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #535b62;
}

.dropDownOptions:hover {
  background: #e7e9ea;
}
.dropDownOptionContainer {
  display: flex;
  flex-direction: column;
}
.dropdownContainer {
  height: max-content;
  width: 304px;
  max-height: 256px;
  overflow-y: auto;
  box-shadow: 0px 1px 10px 0px #00000026;
  border-radius: 6px;
  box-sizing: border-box;
}
.dropdownContainer::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

.dropdownContainer::-webkit-scrollbar-track {
  background: #dde0e4; /* color of the track */
}

.dropdownContainer::-webkit-scrollbar-thumb {
  background: #81878c; /* color of the thumb */
  border-radius: 10px; /* roundness of the thumb */
}
.dropdownSearchLabel {
  position: relative;
}

.dropdownSearchLabel:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 11px;
  bottom: 11px;
  width: 14px;
  height: 14px;
  background: url("./Icons/search.svg") center / contain no-repeat;
}
