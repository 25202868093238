.cardBox {
  height: clamp(16.875rem, 1.757rem + 23.5988vw, 21.875rem);
  float: left;
  font-size: 1.2em;
  perspective: 800px;
  transition: all 0.5s ease 0s;
  width: 100%;
  max-width: 306px;
}

.cardBox:hover .cardDiv {
  transform: rotateY(180deg);
}

.cardDiv {
  cursor: default;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
}

p {
  margin: 0 !important;
}
.cardDiv .front,
.cardDiv .back {
  backface-visibility: hidden;
  height: 100%;
  font-size: 1.2em;
  padding: 16px;
  position: absolute;
  width: 100%;
  border: 0px;
  border-radius: 10px;
  color: #2f363f !important;
  transform: rotateX(0deg);
}

.front {
  background-color: #1f7ae033;
  display: flex;
  justify-content: center;
  align-items: center;
}
.front h3 {
  font-family: Metropolis;
  padding: 0 0;
  width: 200px;
  font-weight: 700;
  font-size: clamp(1rem, -0.5118rem + 2.3599vw, 1.5rem);
  line-height: clamp(1.5rem, -0.7677rem + 3.5398vw, 2.25rem);
  text-align: center;
}
@media screen and (max-width: 1250px) {
  .front h3 {
    padding: 0 12px;
  }
}
.cardDiv p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.cardDiv .back {
  text-align: justify;
  border: 2px solid #1f7ae0;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  padding: 16px ;
}
.backtext {
  font-size: clamp(0.625rem, -0.1309rem + 1.1799vw, 0.875rem) !important;
  line-height: clamp(0.9375rem, -0.1963rem + 1.7699vw, 1.3125rem) !important;
  font-weight: 400 !important;
  font-family: Poppins !important;
  color: #2f363f;
}

.cardBox:nth-child(1) .card .back {
  background: rgb(255, 255, 255);
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 720px) {
  .cardBox {
    height: 210px !important;
    width: 328px !important;
    margin-bottom: 12px;
    border-radius: 10px;
    max-width: 328px !important;
  }
  .cardDiv .back {
    padding: 24px;
    }
  div.cardBox:last-child {
    margin-bottom: 0px !important;
  }
  .front h3 {
    font-family: Metropolis;
    padding: 0 0;
    width: auto;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center;
  }
  .backtext {
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    font-family: Poppins !important;
    color: #2f363f;
  }
}

/* @media screen and (max-width: 767px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 46%;
  }
  .card {
    height: 285px;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
} */

@media screen and (max-width: 480px) {
  .cardBox {
    width: 94.5%;
  }
  .card {
    height: 260px;
  }
}

.front-contain {
}
h3 {
  margin: 0 !important;
}

/* .cardDiv:hover .front-contain {
  display: none;
} */
