.evonextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    107.75deg,
    rgba(31, 122, 224, 0.26) 2.19%,
    #1f7ae0 100%
  );
}
.deprontoComingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.coming-soon-mob-banner {
  display: none;
}

@media screen and (min-width: 720px) and (max-width: 1250px) {
  .coming-soon-banner {
    height: 436px;
    width: 864px;
  }
}

/* 1366 */
@media screen and (min-width: 1250px) and (max-width: 1700px) {
  .coming-soon-banner {
    height: 556px;
    width: 1098px;
  }
}

/* 1920 */
@media screen and (min-width: 1700px) {
  .coming-soon-banner {
    height: 784px;
    width: 1548px;
  }
}

@media screen and (max-width: 720px) {
  .coming-soon-mob-banner {
    display: block;
  }
  .coming-soon-banner {
    display: none;
  }
}
