.aboutUs-page-main-container {
  margin: 32px 32px clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem) 32px;
}
.mobileViewthree{
  display: none;
}
.threeFeature-heading {
  color: #033b81;
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto;
  margin-bottom: clamp(2rem, -1.0236rem + 4.7198vw, 3rem);
}
.threeFeature-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}
.threeFeature {
  /* padding: 0 151px; */
  max-width: 920px;
  /* min-width: 960px; */
  margin: 0 auto;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .threeFeature {
    max-width: 960px;
  }
}
.threeFeature-button {
  padding: 6px 8px 6px 8px;
  border: 1px solid transparent;
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #81878c;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}
.active-Button {
  background-color: #ff7a00 !important;
}
.threeFeature {
  margin-top: clamp(8.875rem, 3.2058rem + 8.8496vw, 10.75rem);
}
@media screen and (max-width: 720px) {
  .mobileViewthree{
    display: flex;
    flex-direction: column;
    margin-top: 48px;
  }
  .threeFeature {
    display: none;
  }
  .threeFeature-heading{
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .aboutUs-page-main-container{
    margin:16px;
    margin-bottom: 48px;
  }
  .mob-threeFeature-heading {
    color: white;
    background-color: #ff7a00;
    width: auto;
    height: 26px;
    padding: 6px 8px;
    border-radius: 4px;
    margin: 0 auto;
    font-family: Metropolis;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
  }
  .mob-threeFeature-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    margin: 32px auto 0 auto;
  }
  .mob-threeFeature-img {
    width: 151px;
    height: 151px;
    border-radius: 10px;
  }
  .mob-threeFeature-text {
    margin-top: 12px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: justify;
    color: #2f363f;
  }
}
