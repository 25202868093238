/* //css without media query is for screen width more than 1366 */
.solutions-Heading {
  font-family: Metropolis !important;
  color: #ff7a00;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  margin-bottom: 32px;
}
.solutions-cards {
  display: flex;
  column-gap: 32px;
  /* height: 600px; */
}
.solutions-container {
  margin: clamp(10.375rem, 4.7058rem + 8.8496vw, 12.25rem) auto 0 auto;
  padding: 0 32px;
  max-width: 1366px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .solutions-Heading {
    font-family: Metropolis;
    /* font-size: 32px; */
    font-weight: 800;
    line-height: 32px;
    text-align: center;
  }
  /* .solutions-container {
    margin: 172px auto 0 auto;
  } */
}
@media screen and (max-width: 1024px) {
  .solutions-Heading {
    font-family: Metropolis;
    /* font-size: 28px; */
    font-weight: 800;
    line-height: 28px;
    text-align: center;
  }
 
  /* .solutions-container {
    margin: 142px auto 0 auto;
  } */
}
@media screen and (max-width: 720px) {
  .solutions-Heading {
    font-family: Metropolis;
    font-size: 18px; 
    font-weight: 800;
    line-height: 18px;
    text-align: center;
    margin-bottom: 16px !important;
  }
  .solutions-cards {
    display:flex;
    flex-direction: column !important;
    margin-bottom: 16px !important;
    /* height: 600px; */
  }
  .solutions-container {
    margin-top: 60px;
    width: 328px;
    padding: 0px;
  }
}
