.form-label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f363f;
  margin-bottom: 4px !important;
}

.supMandatory{
  color: #D82F44;
  font-size: 16px;
  line-height: 24px;
  top:0px;
}
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.contact-form {
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 48px;
  row-gap: 32px;
  max-width: 1041px;
  width: 100%;
}

.message {
  grid-column: span 2;
}

.form-group input,
.form-group textarea {
  font-family: Poppins;
  width: 100%;
  height: 48px;
  border: 1px solid #dde0e4;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px 12px 15px 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #535b62;
}
.form-group input:focus,
.form-group textarea:focus {
  outline: blue;
}

.form-group textarea {
  resize: none !important;
}
.form-group textarea::-webkit-scrollbar {
  display: none;
}

.form-button {
  margin-top: 16px;
  box-sizing: border-box;
  background-color: #1f7ae0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  width: 100px;
  height: 36px;
  top: 915px;
  left: 79px;
  padding: 7.5px 25px 7.5px 25px;
  border-radius: 6px;
  gap: 8px;
  border: 1px solid transparent;
  color: #ffff;
}
/* @media screen and (max-width: 1025px) {
  .contact-form {
    column-gap: 47px;
  }
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.error {
  color: var(--Red-Primary, #d82f44);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}
.dropdownIcon {
  position: absolute;
  top: 40px;
  right: 12px;
}
.dropdownInput {
  cursor: pointer;
}
.companyStrengthDropdownItem {
  padding: 6px 12px !important;
  font-size: 12px !important;
  border-width: 0px 0px 1px 0px;
}

.companyStrengthDropdownContainer {
  width: 136px !important;
  height: 180px !important;
}
.countryCode {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #535b62;
  position: absolute;
  top: 41.5px;
  left: 8px;
}
@media screen and (max-width: 1024px) {
  .countryCode {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #535b62;
    position: absolute;
    top: 42px;
    left: 8px;
  }
}
.msgCharacterCount {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #535b62;
  position: absolute;
  right: 0;
  top: 0;
}
.form-label {
  margin-bottom: 4px !important;
}
@media screen and (max-width: 720px) {
  .occupy-space {
    display: none;
  }
  .supMandatory{
    font-size: 14px;
    line-height: 21px;
  }
  .dropdownIcon {
    top:34px;
    bottom:12px;
  }
  .countryCode {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #535b62;
    position: absolute;
    top: 38.5px;
    left: 8px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .form-label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-bottom: 4px !important;
  }
  .form-group textarea {
    height: 96px;
  }

  .msgCharacterCount {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400px;
  }
  .form-button {
    margin-top: 0px;
    height: 24px;
    width: 64px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400px;
    padding: 3px 11px 3px 10px;
  }
}
