:root {
  --position-offset: 64px;
  --position-x-offset: 64px;
}

/* @media screen and (max-width: 1366px) {
  .bottom-left {
    left: auto; 
  }
} */
.top-left {
  position: absolute;
  top: var(--position-offset);
  left: var(--position-offset);
}
.top-right {
  position: absolute;
  top: var(--position-offset);
  right: var(--position-offset);
}
.bottom-left {
  position: absolute;
  bottom: var(--position-offset);
  left: var(--position-offset);
}
.bottom-right {
  position: absolute;
  bottom: var(--position-offset);
  right: var(--position-offset);
}
.Banner-Image-Text-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 39%;
}
.Banner-Image {
  width: 100%;
  height: 627px;
  /* max-width: 1379px; */
  max-height: 627px;
  border-radius: 16px;
  /* object-fit: cover; */
}
@media screen and (max-width: 1024px) {
  .Banner-Image {
    width: 100%;
    height: 627px;
    
    /* max-width: 1379px; */
    max-height: 627px;
    border-radius: 16px;
     /* object-fit: contain;  */
  }
}
@media screen and (max-width: 720px) {

  .Banner-Image-Text-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 144px !important;
    width: 280px !important;
    margin :42px 32px 388px 16px;
   
  }
  .top-left {
    position:unset;
   
  }
  .Banner-Image{
    width: 100%;
   height:574px !important;
   border-radius: 8px;
  }
  .BannerImg-title {
    font-family: Metropolis;
    font-size:18px !important;
    font-weight: 800;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 4px !important;
  }
  .BannerImg-Button {
    font-family: Metropolis;
    font-size: 12px !important;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 92px !important;
    height: 32px !important;
    padding: 4px 8px !important;
    border-radius: 6px;
    background-color: #ff7a00;
    color: #ffff;
    border-color: #ff7a00;
    overflow: hidden;
    box-sizing: border-box;
  }
  .BannerImg-subTitle {
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 18px  !important;
  }
  
}

.Banner-Image-filter::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    100.25deg,
    #0d0c54 0%,
    #1c21a8 43.5%,
    #1f7ae0 96.5%
  );
  opacity: 0.4;
  border-radius: 16px;
}

.Banner-Image-Container {
  position: relative;
}
.BannerImg-Button {
  font-family: Metropolis;
  font-size: 1rem;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 134px;
  height: 48px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #ff7a00;
  color: #ffff;
  border-color: #ff7a00;
  overflow: hidden;
  box-sizing: border-box;
}
button {
  all: initial;
}

.BannerImg-title {
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
}
@media screen and (max-width: 1150px) {
  .BannerImg-title {
    line-height: 28px;
  }
}
h1,
h2 {
  margin: 0;
}
.BannerImg-subTitle {
  font-family: Poppins !important;
  font-size: clamp(1rem, 0.2441rem + 1.1799vw, 1.25rem);
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}
