.slidecard-main-container {
  height: 1114px;
}
.careerOpprtunities-container {
  display: flex;
  justify-content: space-between;
}

.careerOpprtunities-heading {
  font-family: poppins;
  text-align: center;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: clamp(2.625rem, 1.4912rem + 1.7699vw, 3rem);
  margin-bottom: 32px !important;
  color: #052166;
}
.grid-item2 {
  /* width: calc(100% - clamp(5rem, -12.7636rem + 27.7286vw, 10.875rem)); */
  width: clamp(55rem, 8.1342rem + 73.1563vw, 70.5rem);
  position: relative;
  left: clamp(5rem, -12.7636rem + 27.7286vw, 10.875rem);
}
.carrerimg {
  width: auto;
  position: absolute;
  z-index: -20;
  height: clamp(36.25rem, 0.5337rem + 55.7522vw, 48.0625rem) !important;
  width: clamp(34.375rem, 23.7924rem + 16.5192vw, 37.875rem) !important;
}

@media screen and (max-width: 720px) {
  .carrerimg {
  width: auto;
  position: absolute;
  z-index: -20;
  height:282px  !important;
  width:220px !important;
  border-radius: 16px !important;
  }
  .careerOpprtunities-heading {
    font-family: poppins;
    text-align: center;
    font-size:18px !important;
    font-weight: 800;
    line-height: 27px !important;
    margin-bottom: 16px !important;
    color: #052166;
  }
  .grid-item2 {
    /* width: calc(100% - clamp(5rem, -12.7636rem + 27.7286vw, 10.875rem)); */
    width: clamp(55rem, 8.1342rem + 73.1563vw, 70.5rem);
    position: relative;
    left: 128px !important;
  }
  .careerOpprtunities-container {
    height: 282px;
  }
}

/* box-shadow: 4px 0px 36px 2px rgba(83, 91, 98, 0.18); */
