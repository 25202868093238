.solution-card-container {
  width: 100%;
  height: clamp(37.5rem, 33.3426rem + 6.4897vw, 38.875rem);

  padding: 48px;
  padding-bottom: 20%;
  padding-right: 7%;
  position: relative;
  border-radius: 7px;
  max-width: 661px;
}
.Solution-TextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.Solution-Heading {
  /* width: 125%; */
  font-family: poppins;
  font-size: clamp(1rem, -0.5118rem + 2.3599vw, 1.5rem);
  font-weight: 700;
  line-height: clamp(1.5rem, -0.7677rem + 3.5398vw, 2.25rem);
  letter-spacing: 0em;
  text-align: left;
}
.Solution-Description {
  font-family: Poppins;
  /* font-size: 20px; */
  font-size: clamp(0.875rem, 0.4971rem + 0.59vw, 1rem);
  font-weight: 400;
  line-height: clamp(1.3125rem, 0.7456rem + 0.885vw, 1.5rem);
  letter-spacing: 0em;
  text-align: left;
}

.Solution-Button {
  z-index: 100;
  width: 120px;
  height: 36px;
  /* padding: 7.5px 22px; */
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 6px;
  /* border: 1px solid transparent; */
}
.Solution-logo-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
/* .Solution-logo-container img {
  margin-left: 18%;
} */
@media screen and (max-width: 720px) {
  .solution-card-container {
    width: 100%;
    height: 400px;
    padding-bottom: 132px !important;
    padding-right: 24px;
    padding-left: 12px;
    padding-top: 24px;
    position: relative;
    border-radius: 7px;
    max-width: 328px;
    margin: 0 auto;
    margin-bottom: 16px;
    max-width: 328px;
    align-items: center;
  }
  .solution-card-container:last-child {
    margin-bottom: 0px;
  }
  .Solution-Heading {
    /* width: 125%; */
    font-family: poppins;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 24px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  .Solution-TextContainer {
    display: flex;
    flex-direction: column;
    width: 292px;
    height: 244px;
    align-items: flex-start;
    gap: 8px;
  }
  .Solution-Description {
    font-family: Poppins;
    /* font-size: 20px; */
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .Solution-Button {
    z-index: 100;
    width: 82px;
    height: 24px;
    /* padding: 7.5px 22px; */
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 3px;
    /* border: 1px solid transparent; */
  }
  .Solution-logo-container {
    position: absolute;
    bottom: 12px !important;
    right: 12px !important;
  }
}
