.exp-card-container {
  display: flex;
  gap: 28px;
  justify-content: space-between;
}
/* .exp-card-image-container {
  width: 37.15%;
}
.exp-card-text-container {
  width: 59.92%;
} */
.exp-card-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f363f;
  margin: 0;
  text-align: justify;
}
.exp-card-text-container {
  display: flex;
  align-items: center;
  /* padding: 50px 0; */
}
.exp-card-img {
  height: 250px;
  width: 250px;
  max-width: 400px;
  max-height: 400px;
  border-radius: 10px;
}
