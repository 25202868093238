.formSuccessContainer {
  /* height: 700px; */
  width: 100%;
  position: relative;
}
.BackButton {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.SuccessTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thanksText {
  font-family: Metropolis;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: #1f7ae0;
  margin-top: 32px;
  margin-bottom: 6px;
}
.SucessText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #535b62;
  margin-top: 2px;
}
.navigateToHome {
  background-color: #1f7ae0;
  color: #ffffff;
  box-sizing: border-box;
  height: 36px;
  width: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin-top: 64px;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (min-width: 720px) and (max-width: 1250px) {
  .sucessImg {
    height: 128px;
    width: 128px;
  }
  .thanksText {
    font-family: Metropolis;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    margin-top: 36px;
    margin-bottom: 10px;
  }
  .SucessText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-top: 2px;
  }
  .navigateToHome {
    margin-top: 36px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}

/* mobile */
@media screen and (max-width: 720px) {
  .sucessImg {
    height: 96px;
    width: 96px;
  }
  .thanksText {
    font-family: Metropolis;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .SucessText {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    margin-top: 4px;
  }
  .navigateToHome {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    width: 146px;
    height: 36px;
    margin-top: 12px;
  }
  .SuccessTextContainer {
    padding-top: 32px;
  }
}
