.button {
  border: none;
  padding: 0%;
  margin: 0%;
  border-radius: 100%;
}
.arrowButton {
  display: flex;
  justify-content: end;
  padding-right: 12px;
  gap: clamp(0.75rem, -0.5728rem + 2.0649vw, 1.1875rem);
}
.container-fluid.slidecard-main-container {
  height: clamp(40.875rem, 4.0249rem + 57.5221vw, 53.0625rem);
  padding: 0 !important;
}
.arrow-svg {
  height: clamp(1.5rem, 0.0073rem + 2.3324vw, 2rem);
  width: clamp(1.5rem, 0.0073rem + 2.3324vw, 2rem);
  /* height: clamp(1.25rem, -1.0177rem + 3.5398vw, 2rem);
  width: clamp(1.25rem, -1.0177rem + 3.5398vw, 2rem); */
  /* font-size: clamp(1.5rem, 0.0073rem + 2.3324vw, 2rem); */
}
.SliderBox{
  width: 1158px ;
}
@media (max-width: 1367px) {
  .arrow-svg {
    height: 32px;
    width: 32px;
  }

}
@media (max-width: 1250px) {
  .SliderBox{
    width: 912px;
  }
  .arrow-svg {
    height: 24px;
    width: 24px;
  }
}
@media screen and (max-width: 720px) {
  .arrow-svg {
    height: 20px;
    width: 20px;
  }

  .arrowButton {
    width: 200px;
    gap: 8px;
    padding-right: 0px;
  }

  .container-fluid.slidecard-main-container {
    height: 230px !important;
    padding-left: 0px;
    padding-right: 0px;
    width: 328px;
  }
}

.slick-prev:before {
  content: "." !important;
  display: none;
}
.slick-arrow {
  display: none !important;
}
